import { navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import IndexPage from ".";
import ClassBanner from "../../components/classes/ClassBanner";
import ClassesSlider from "../../components/classes/ClassesSlider";
import ClassTabs from "../../components/classes/ClassTabs";
import Categories from "../../components/explore/Categories";
import ClassesGrid from "../../components/explore/ClassesGrid";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout";
import VideoPlayer from "../../components/VideoPlayer";
import { useAuth } from "../../context/AuthContext";
import { getClass, useFetch } from "../../services/classes";

const Category = ({ params }) => {
	const category = params["category"];

	return <IndexPage category={category} />;
};

export default Category;
